var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "mt-4"
  }, [_c('i', {
    staticClass: "fa fa-backward"
  }), _c('router-link', {
    attrs: {
      "to": {
        name: _vm.NarratorPath.name
      }
    }
  }, [_vm._v(" Narrators ")])], 1), _c('b-form', {
    on: {
      "submit": _vm.onSubmit,
      "reset": _vm.onReset
    }
  }, [_c('b-card', {
    attrs: {
      "header": "Create New Narrator",
      "header-bg-variant": "primary",
      "header-tag": "h5",
      "header-class": "font-weight-bold"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-card', {
    staticClass: "bg-info text-dark"
  }, [_vm._v("(*) Kolom berlabel bintang wajib diisi.")])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name (*)"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Narrator Name",
      "required": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Birth Date (*)"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "today-button": "",
      "required": "",
      "id": "birthdate",
      "autocomplete": "off"
    },
    model: {
      value: _vm.form.birthdate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "birthdate", $$v);
      },
      expression: "form.birthdate"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "First (*)"
    }
  }, [_c('b-form-input', {
    attrs: {
      "required": "",
      "type": "text",
      "placeholder": "First Name"
    },
    model: {
      value: _vm.form.first_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "first_name", $$v);
      },
      expression: "form.first_name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Last (*)"
    }
  }, [_c('b-form-input', {
    attrs: {
      "required": "",
      "type": "text",
      "placeholder": "Last Name"
    },
    model: {
      value: _vm.form.last_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "last_name", $$v);
      },
      expression: "form.last_name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Academic Title"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "example: S.H., M.H."
    },
    model: {
      value: _vm.form.academic_title,
      callback: function ($$v) {
        _vm.$set(_vm.form, "academic_title", $$v);
      },
      expression: "form.academic_title"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Description"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "description",
      "placeholder": "Enter something...",
      "rows": "5"
    },
    model: {
      value: _vm.form.meta,
      callback: function ($$v) {
        _vm.$set(_vm.form, "meta", $$v);
      },
      expression: "form.meta"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "value": true,
      "required": "",
      "unchecked-value": false
    },
    model: {
      value: _vm.form.is_active,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_active", $$v);
      },
      expression: "form.is_active"
    }
  }, [_vm._v("Active (*) ")])], 1)], 1)], 1)], 1), _c('b-card', [_c('b-row', {
    staticClass: "align-items-end"
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Select Image"
    }
  }, [_c('input', {
    staticClass: "form-control-file",
    attrs: {
      "type": "file",
      "accept": "image/*",
      "id": "my-file"
    },
    on: {
      "change": _vm.previewImage
    }
  })])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "border p-2 mt-3"
  }, [_c('p', [_vm._v("Preview Here:")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.form.imagesrc ? [_c('img', {
    staticClass: "img-fluid",
    staticStyle: {
      "width": "30%"
    },
    attrs: {
      "src": _vm.form.imagesrc
    }
  })] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.form.preview ? [_c('img', {
    staticClass: "img-fluid",
    staticStyle: {
      "width": "30%"
    },
    attrs: {
      "src": _vm.form.preview
    }
  }), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("file name: " + _vm._s(_vm.form.image.name))]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("size: " + _vm._s(Math.ceil(_vm.form.image.size / 1024)) + " KB")])] : _vm._e()], 2)], 1)], 1)])], 1)], 1), _c('b-row', [_c('b-col', [_c('b-button', {
    staticClass: "mx-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v("Submit")]), _c('b-button', {
    staticClass: "mx-1",
    attrs: {
      "type": "reset",
      "variant": "secondary"
    }
  }, [_vm._v("Reset")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }