<template>
    <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="secondary"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="secondary"></b-spinner>
        </div>
      </template>
      <div class="mt-4">
        <i class="fa fa-backward"></i>
        <router-link :to="{ name: NarratorPath.name }"> Narrators </router-link>
      </div>
      <b-form @submit="onSubmit" @reset="onReset">
      <b-card header="Create New Narrator" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
        <b-row>
          <b-col lg="6" class="mt-4">
            <b-card class="bg-info text-dark">(*) Kolom berlabel bintang wajib diisi.</b-card>
          </b-col>
        </b-row>
        <b-row>
            <b-col md="6">
              <b-form-group label="Name (*)">
                <b-form-input v-model="form.name" type="text" placeholder="Narrator Name" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Birth Date (*)">
                <b-form-datepicker today-button  required  id="birthdate" autocomplete="off"
                  v-model="form.birthdate"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" lg="4">
              <b-form-group label="First (*)">
                <b-form-input v-model="form.first_name"  required  type="text" placeholder="First Name"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" lg="4">
              <b-form-group label="Last (*)">
                <b-form-input v-model="form.last_name"  required  type="text" placeholder="Last Name"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" lg="4">
              <b-form-group label="Academic Title" >
                <b-form-input v-model="form.academic_title" type="text" placeholder="example: S.H., M.H."></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Description">
                <b-form-textarea id="description" v-model="form.meta" placeholder="Enter something..."
                  rows="5"></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <b-form-checkbox v-model="form.is_active" :value="true" required :unchecked-value="false">Active (*)
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
      </b-card>
      <b-card>
        <b-row class="align-items-end">
            <b-col md="4">
              <b-form-group label="Select Image">
                <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file">
              </b-form-group>
            </b-col>
            
          </b-row>
          <b-row>
            <b-col md="12">
              <div class="border p-2 mt-3">
                <p>Preview Here:</p>
                <b-row>
                  <b-col md="6">
                    <template v-if="form.imagesrc">
                      <img :src="form.imagesrc" class="img-fluid" style="width: 30%" />
                    </template>
                  </b-col>
                  <b-col md="6">
                    <template v-if="form.preview" >
                      <img :src="form.preview" class="img-fluid" style="width: 30%"/>
                      <p class="mb-0">file name: {{ form.image.name }}</p>
                      <p class="mb-0">size: {{  Math.ceil(form.image.size/1024) }} KB</p>
                    </template>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
      </b-card>
      <b-row>
        <b-col>
          <b-button class="mx-1" type="submit" variant="primary">Submit</b-button>
          <b-button class="mx-1" type="reset" variant="secondary">Reset</b-button>
        </b-col>
      </b-row>
    </b-form>
    </b-overlay>
  </template>
  
  <script>
  import moment from "moment";
  import { mapActions, mapState } from 'vuex';
  import { NarratorPath } from '../../router/content';
  
  export default {
    data() {
      return {
        form: {
          name: null,
          is_active: true,
          birthdate: moment().toString(),
          first_name: null,
          last_name: null,
          academic_title: null,
          meta: null,
          preview: null,
          image: null,
          imagesrc: null,
        },
        NarratorPath,
      }
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.narrator.isLoading,
        isError: (state) => state.narrator.isError,
        errorMessage: (state) => state.narrator.errorMessage,
        successMessage: (state) => state.narrator.successMessage,
      }),
    },
    watch: {
      errorMessage: function (message) {
        if (!this.errorMessage) return;
        this.messageAlert('error', message, 5000);
      },
      successMessage: function (message) {
        if (!this.successMessage) return;
        this.messageAlert('success', message);
        this.$router.push(this.NarratorPath);
      },
    },
    methods: {
      ...mapActions('narrator', [
        'postNarratorWithImage',
      ]),
      previewImage: function(event) {
        var input = event.target;
        if (input.files) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.form.preview = e.target.result;
          }
          this.form.image=input.files[0];
          reader.readAsDataURL(input.files[0]);
        }
      },
      onSubmit(event) {
        event.preventDefault();
        const payload = this.form;
        this.postNarratorWithImage(payload);
      },
      onReset() {
        Object.assign(this.$data, this.$options.data.call(this));
      },
      messageAlert(icon, title, timer = 3000) {
        this.$swal({
          toast: "true",
          position: "top-end",
          icon,
          title,
          showConfirmButton: false,
          timer,
          timerProgressBar: true,
        });
      },
    },
  }
  </script>